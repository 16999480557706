import { login, getInfo, modifyPassword } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const state = {
  token: getToken(),
  maxNumbers: 0,
  name: '',
  avatar: '',
  password: '',
  introduction: '',
  roles: [],
  userId: null
}

const mutations = {
  SET_MAX_NUMBERS: (state, maxNumbers) => {
    state.maxNumbers = maxNumbers
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_PASSWORD: (state, password) => {
    state.password = password
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USERID: (state, userId) => {
    state.userId = userId
  },
  CLEAN_UP: (state) => {
    state.token = ''
    state.name = ''
    state.avatar = ''
    state.password = ''
    state.introduction = ''
    state.roles = []
    state.userId = null
  }
}

const actions = {
  cleanUp({ commit }) {
    commit('CLEAN_UP')
  },
  SetMaxNumbers: ({ commit }, maxNumbers) => {
    commit('SET_MAX_NUMBERS', maxNumbers)
  },
  updatePassowrd({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      modifyPassword(userInfo).then(response => {
        const { data } = response
        commit('SET_PASSWORD', data.password)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  login({ commit }, userInfo) {
    const { username, password, rememberMe } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password, rememberMe }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { roles, name, avatar, introduction, userId, password, maxNumbers } = data

        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_PASSWORD', password)
        commit('SET_AVATAR', avatar)
        commit('SET_USERID', userId)
        commit('SET_INTRODUCTION', introduction)
        commit('SET_MAX_NUMBERS', maxNumbers)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_ROLES', [])
    removeToken()
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
