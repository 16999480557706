import { stringToColor } from '@/utils/color'
import { updateMessage } from '@/utils/indexdb'


const state = {
  contacts: [],
  messages: [],
  unread: 0,
  unreadMessage: [],
  lastMessages: [],
  sendList: [],
  checkList: [],
  changeCount: 0,
  selectContact: null
}

const mutations = {
  SET_CONTACT: (state, list) => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      item.color = stringToColor(item.phone)
    }
    state.contacts = list
  },
  SET_UNREAD: (state, unread) => {
    state.unread = unread
  },
  SET_UNREAD_MESSAGE: (state, list) => {
    state.unreadMessage = list
    state.unread = list.length
  },
  ADD_UNREAD_MESSAGE: (state, message) => {
    state.unreadMessage.push(message)
    state.unread++
  },
  SET_MESSAGE: (state, list) => {
    state.messages = list
    // reset sendList
    state.sendList = []
  },
  PUSH_MESSAGE: (state, message) => {
    state.messages.push(message)
  },
  UPDATE_MESSAGE: (state, info) => {
    const messages = state.messages
    for (let i = messages.length - 1; i > -1; i--) {
      const message = messages[i];
      if (message.messageId === info.messageId) {
        message.status = info.status
        break
      }
    }
    state.messages = [...messages]
    updateMessage(info)
  },
  PUSH_SEND_MESSAGE: (state, msg) => {
    const old = state.sendList
    state.sendList = [msg,...old]
  },
  REMOVE_SEND_MESSAGE: (state, msg) => {
    for (let i = 0; i < state.sendList.length; i++) {
      const item = state.sendList[i];
      if (item.messageId === msg.messageId) {
        state.sendList.splice(i, 1)
      }
    }
  },
  PUSH_CHECK_MESSAGE: (state, msg) => {
    const old = state.checkList
    state.checkList = [msg,...old]
  },
  REMOVE_CHECK_MESSAGE: (state, msg) => {
    for (let i = 0; i < state.checkList.length; i++) {
      const item = state.checkList[i];
      if (item.messageId === msg.messageId) {
        state.checkList.splice(i, 1)
      }
    }
  },
  UPDATE_SELECT_CONTACT: (state, contact) => {
    state.selectContact = contact
  },
  ADD_CONTACT: (state, item) => {
    const contacts = state.contacts
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      if (contact.phone === item.phone) {
        return
      }
    }
    item.color = stringToColor(item.phone)
    item.selected = false
    item.focus = true
    state.contacts.push(item)
  },
  REMOVE_CONTACT: (state, item) => {
    const contacts = state.contacts
    const newArr = []
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      if (contact.phone !== item.phone) {
        newArr.push(contact)
      }
    }
    state.contacts = newArr
  },
  SET_LAST_MESSAGES: (state, messages) => {
    state.lastMessages = messages
  },
  REMOVE_LAST_MESSAGES: (state, messages) => {
    const lastMessages = state.lastMessages
    for (let j = 0; j < messages.length; j++) {
      const message = messages[j];
      for (let i = 0; i < lastMessages.length; i++) {
        const element = lastMessages[i]
        if (message.phone === element.phone) {
          lastMessages.splice(i, 1)
          break
        }
      }
    }
  },
  UPDATE_LAST_MESSAGE: (state, message) => {
    const lastMessages = state.lastMessages
    let find = false
    const { time, body, to} = message
    for (let i = 0; i < lastMessages.length; i++) {
      const element = lastMessages[i]
      if (to === element.phone) {
        element.time = time
        element.content = body
        find = true
        break
      }
    }
    if (!find) {
      lastMessages.push({
        time,
        phone: to,
        content: body
      })
    }
    state.lastMessages = [...lastMessages]
  },
  CLEAN_UP: (state) => {
    state.contacts = []
    state.messages = []
    state.unread = 0
    state.unreadMessage = []
    state.lastMessages = []
    state.sendList = []
    state.checkList = []
    state.changeCount = 0
    state.selectContact = null
  }
}

const actions = {
  cleanUp({ commit }) {
    commit('CLEAN_UP')
  },
  SetLastMessages: ({ commit }, messages) => {
    commit('SET_LAST_MESSAGES', messages)
  },
  RemoveLastMessages: ({ commit }, messages) => {
    commit('REMOVE_LAST_MESSAGES', messages)
  },
  UpdateLastMessage: ({ commit }, message) => {
    commit('UPDATE_LAST_MESSAGE', message)
  },
  SetContact: ({ commit }, contacts) => {
    commit('SET_CONTACT', contacts)
  },
  SetUnreadMessage: ({ commit }, messages) => {
    commit('SET_UNREAD_MESSAGE', messages)
    commit('SET_UNREAD', messages.length)
  },
  AddUnreadMessage: ({ commit }, message) => {
    commit('ADD_UNREAD_MESSAGE', message)
  },
  PushMessage: ({ commit }, message) => {
    commit('PUSH_MESSAGE', message)
  },
  UpdateMessage: ({ commit }, message) => {
    commit('UPDATE_MESSAGE', message)
  },
  PushSendMessage: ({ commit }, message) => {
    commit('PUSH_SEND_MESSAGE', message)
  },
  RemoveSendMessage: ({ commit }, message) => {
    commit('REMOVE_SEND_MESSAGE', message)
  },
  PushCheckMessage: ({ commit }, message) => {
    commit('PUSH_CHECK_MESSAGE', message)
  },
  RemoveCheckMessage: ({ commit }, message) => {
    commit('REMOVE_CHECK_MESSAGE', message)
  },
  SetMessage: ({ commit }, list) => {
    commit('SET_MESSAGE', list)
  },
  UpdateSelectContact: ({ commit }, item) => {
    commit('UPDATE_SELECT_CONTACT', item)
  },
  AddContact({ commit }, item) {
    commit('ADD_CONTACT', item)
  },
  RemoveContact({ commit }, item) {
    commit('REMOVE_CONTACT', item)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
